import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MainContext } from 'Context/mainContext'
import { SocketContext } from 'Context/socketContext'
import { Tooltip, Spinner, Text } from "@chakra-ui/react"
import './chatv2.scss'
import { RiFilter3Fill, RiLogoutBoxLine  } from "react-icons/ri";
// import { UsersContext } from 'Context/usersContext'
import { httpUtils } from 'utils/httpUtils'
import { domains, LIMIT_MSG } from 'utils/configs'
import moment from 'moment'
import { Box, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import TabMessage from '../Tabs/TabMessage'
import TabNotify from '../Tabs/TabNotify'
const Chat = () => {
    
    const { name, room, setName, setRoom } = useContext(MainContext)
    const socket = useContext(SocketContext)
    
    const [isLoading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    const [notifications, setNotifications] = useState([])
    const [rooms, setRooms] = useState([])
    const [countNoti, setCountNoti] = useState(window.localStorage.getItem('count_noti') ?? 0);
    const [countMsg, setCountMsg] = useState(window.localStorage.getItem('count_msg') ?? 0);
    const [indexTab, setIndexTab] = useState(window.localStorage.getItem('tab_index') ?? 0);
    const checkApp = window.localStorage.getItem('app') === "true" ? true : false;
    // const { users } = useContext(UsersContext)
    const is_admin = window.localStorage.getItem('is_admin') || 0;
    const history = useHistory()
    const [isConnected, setConnected] = useState({reset: false, connect: true})

    window.onpopstate = e => logout()
    //Checks to see if there's a user present
    
    useEffect(() => {
        if (!name) return history.push('/')
        // getMessage()
        const getRooms = () => {
            if(Number(is_admin) !== 1) return
            httpUtils.getRequest(`${domains.HOST_DOMAIN}/api/room`).then(async res => {
                if(res.status === 1) {
                    let data = res.data
                    setRooms(data);
                }
            })
        }
        const getNotify = () => {
            httpUtils.getRequest(`${domains.HOST_DOMAIN}/api/message?room=thong-bao&limit=${LIMIT_MSG.NOTI}`).then(async res => {
                if(res.status === 1) {
                    let data = res.data
                    data = await setDate(data)
                    setNotifications(data);
                }
            })
        }

        getNotify()
        getRooms()
        switch (Number(indexTab)) {
            case 0:
                setCountFnc(0, 'count_msg')
                break;
            case 1:
                setCountFnc(0, 'count_noti')
                break;
        
            default:
                break;
        }
        return () => {};
    }, [history, indexTab, is_admin, name])

    // lay data
    useEffect(() => {
        const handleMessage = (msg) => {
            if(!msg) return;
            msg.time = moment().format('HH:mm');
            setMessages(messages => [...messages, msg]);
            if (Number(indexTab) === 1) {
                setCountFnc(Number(countMsg) + 1, 'count_msg')
            }
            window.parent.postMessage({msg: 1}, "*");
        }
        const handleNotify = (res) => {
            if(!res) return;
            if(res.type === 2) {
                setRooms(rooms => [...rooms, res.room]);
                return
            }
            let notif = res.content
            notif.time = moment().format('HH:mm');
            notif.content = notif?.description
            setNotifications(notifyold => [...notifyold, notif]);
            if (Number(indexTab) === 0) {
                setCountFnc(Number(countNoti) + 1, 'count_noti')
            }
            window.parent.postMessage({noti: notif.content}, "*");
            // toast({
            //     position: "top",
            //     // title: notif?.title,
            //     // description: notif?.description,
            //     // status: "success",
            //     duration: 2000,
            //     render: () => (
            //         <Box color='white' fontSize=".8rem" minW="150px" borderRadius='8px' mt={2} p={3} bg='green.500'>
            //             <h2>{notif?.title}</h2>
            //             <p className="white-space">{notif?.description}</p>
            //         </Box>
            //     ),
            // })
        }
        socket.on("message", handleMessage)
        socket.on("notification", handleNotify)

        socket.on('disconnect', function(){
            setConnected({reset: true, connect: false})
        });
        return () => {
            socket.off("notification", handleNotify)
            socket.off("message", handleMessage)
        };

        
    }, [socket, indexTab, countNoti, countMsg])

    useEffect(() => {
        socket.on('connect', function(){
            if(isConnected.reset) {
                window.location.reload();
            }
        });
    }, [isConnected, socket])

    useEffect(() => {
        const getMessage = () => {
            httpUtils.getRequest(`${domains.HOST_DOMAIN}/api/message?room=${encodeURIComponent(room)}&limit=${LIMIT_MSG.MSG}`).then(async res => {
                if(res.status === 1) {
                    let data = res.data
                    data = await setDate(data)
                    setMessages(data);
                    setLoading(false)
                }
            })
        }
        if(!room) return
        getMessage();
    }, [room])

    const handleChangeRoom = (room) => {
        socket.emit('changeRoom', room);
        window.localStorage.setItem('room', room);
        setRoom(room);
    }

    const setDate = async (data) => {
        let res = await Promise.all(data.map(async (item) => {
            try {
                item.time = moment(item.createdAt).format('HH:mm');
                return item; 
            } catch(error) {
               return {...item, error } ;
            }
        }));
        return res
    }
    const logout = () => {
        window.localStorage.clear()
        history.push('/')
        history.go(0)
    }
    const logoutApp = () => {
        history.push('/logout')
        history.go(0)
    }
    const setCountFnc = (number, key = 'count_msg') => {
        switch (key) {
            case 'count_msg':
                setCountMsg(number);
                break;
            case 'count_noti':
                setCountNoti(number);
                break;
            default:
                break;
        }
        
        window.localStorage.setItem(key, number)
        return;
    }
    const setTabIndex = (index) =>{
        if(index === 2) {
            index = 0
        }
        window.localStorage.setItem('tab_index', index)
        switch (Number(index)) {
            case 0:
                setCountFnc(0, 'count_msg')
                setIndexTab(index);
                break;
            case 1:
                setCountFnc(0, 'count_noti')
                setIndexTab(index);
                break;
        
            default:
                break;
        }
        
    }
    return (
        <Flex className={`room ${checkApp ? 'mobile_app' : ''}`} flexDirection='column' width={{ base: "100%" }} height={{ base: "100%" }}>
            <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={Number(indexTab)}>
                <TabList>
                    <Tab className="tab-content w-50" _selected={{ color: '#0043A5', borderColor: '#0043A5' }}>
                        {(Number(is_admin) !== 1 && rooms.length < 2) ? (<>Tin nhắn{countMsg > 0 && (<span className="count_msg">{countMsg}</span>)}</>) : (
                            <Menu>
                                <MenuButton>
                                    <Flex alignItems='center' justifyContent='center'>
                                        <Box fontSize="1.5rem" mr="2"><RiFilter3Fill /></Box>
                                        
                                    </Flex>
                                    
                                </MenuButton>
                                <Text className='max_string'>
                                    <Tooltip label={room} aria-label='A tooltip'>
                                        {room}
                                    </Tooltip>
                                </Text>
                                
                                <MenuList>
                                    {rooms.map((e, index) => {
                                        return (
                                            <MenuItem key={`menu_${index}`} onClick={() => { handleChangeRoom(e.name) }}>
                                                {e.name}
                                            </MenuItem>
                                        )
                                    })}
                                </MenuList>
                            </Menu>
                        )}
                    </Tab>
                    <Tab className="tab-content w-50" _selected={{ color: '#0043A5', borderColor: '#0043A5' }}>Thông báo {countNoti > 0 && (<span className="count_noti">{countNoti}</span>)}</Tab>
                    {checkApp && (
                        <div className="tab-logout" _selected={{ borderColor: '#0043A5' }} onClick={logoutApp}><RiLogoutBoxLine /></div>
                    )}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        { isLoading ? (
                            <div className="box_loading_spinner">
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                                />
                            </div>
                            
                        ) : (
                            <TabMessage messages={messages} isConnected={isConnected} />
                        )}
                        
                    </TabPanel>
                    <TabPanel>
                        <TabNotify notifications={notifications} isConnected={isConnected} is_admin={is_admin} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {
                !isConnected.connect && (
                // true && (
                    <div className="error_connect">
                        <Spinner
                            thickness='1px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='red.500'
                            size='xs'
                        />
                        <span className="ml-2">Đang mất kết nối</span>
                        
                    </div>
                )
            }
        </Flex>
    )
}

export default Chat
