import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MainContext } from '../../Context/mainContext'
import { SocketContext } from '../../Context/socketContext'
import { Flex, Heading, IconButton, Input, Spinner } from "@chakra-ui/react"
import { RiArrowRightLine } from "react-icons/ri"
import { useToast } from "@chakra-ui/react"
import { UsersContext } from '../../Context/usersContext'
import { httpUtils } from 'utils/httpUtils'
import moment from 'moment'
import { domains } from 'utils/configs'

const Login = () => {
    const socket = useContext(SocketContext)
    const { name, setName, room, setRoom } = useContext(MainContext)
    const [isAdmin, setAdmin] = useState(0)
    const [color, setColor] = useState(0)
    const [tokenMd5, setToken] = useState('')
    const [code, setCode] = useState('')
    const [showLogin, setShowLogin] = useState(false)
    const history = useHistory()
    const toast = useToast()
    const { setUsers } = useContext(UsersContext)
    // const query = new URLSearchParams(window.location.search)
    //Checks to see if there's a user already present
    const handleClickLogin = (e) => {
        httpUtils.CheckCodeUser(`${domains.HOST_DOMAIN}/api/supporter`, code);
    }
    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        let name = query.get('name')
        let room = query.get('room')
        let token = query.get('token')
        let is_admin = query.get('is_admin')
        let color = query.get('color')
        let app = query.get('app')
        if(!name) {
            name = window.localStorage.getItem('name')
            if(!name) return setShowLogin(true)
        }
        window.localStorage.setItem('name', name)
        if(!room) {
            room = window.localStorage.getItem('room')
            if(!room) return
        }
        window.localStorage.setItem('room', room)
        if(!is_admin) {
            is_admin = window.localStorage.getItem('is_admin')
            if(!is_admin) is_admin = 0
        }
        window.localStorage.setItem('is_admin', is_admin)

        if(!color) {
            color = window.localStorage.getItem('color')
            if(!color) color = 0
        }
        window.localStorage.setItem('color', color)

        if(!app) {
            app = window.localStorage.getItem('app')
            if(!app) app = false
        }
        window.localStorage.setItem('app', app)
        let currentTime = moment().unix();
        let timeLogin = window.localStorage.getItem('time_login');
        if(!timeLogin) {
            window.localStorage.setItem('time_login', moment().unix())
        } else {
            
            if(currentTime - timeLogin > 60*60*24) {
                window.location.assign('/logout')
            }
        }
        
        setToken(token)
        setAdmin(is_admin)
        setName(name);
        setRoom(room);
        setColor(color);

        socket.on("users", users => {
            setUsers(users)
        })

    }, []);

    useEffect(() => {
        const handleClick = () => {
            socket.emit('login', { name, room, check_sum: tokenMd5, is_admin: isAdmin, color }, error => {
                if (error) {
                    console.log(error)
                    return toast({
                        position: "top",
                        title: "Error",
                        description: error,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                }
                history.push('/chat')
            })
        }
        if(!name || !room) return
        handleClick();
    }, [name, room])
    //Emits the login event and if successful redirects to chat and saves user data

    return (
        <>
            {showLogin && (
                <Flex className='login' flexDirection='column' mb='8'>
                    <Heading as="h1" size="4xl" textAlign='center' mb='8' fontFamily='DM Sans' fontWeight='600' letterSpacing='-2px'>
                        {/* Chat khảo thí */}
                        {/* <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        /> */}
                    </Heading>
                    <Flex className="form" gap='1rem' flexDirection={{ base: "column", md: "row" }}>
                        <Input variant='filled' mr={{ base: "0", md: "4" }} mb={{ base: "4", md: "0" }} type="text" placeholder='Code' value={code} onChange={e => {
                            let value = e.target.value
                            if(value) {
                                value = value.toUpperCase()
                            }
                            setCode(value)
                        }} />
                        <IconButton colorScheme='blue' isRound='true' icon={<RiArrowRightLine />} onClick={handleClickLogin}></IconButton>
                    </Flex>
                </Flex>
            )}
        </>
        
    )
}

export default Login
