const {domains} = require('./configs')
const moment = require('moment')
async function postRequest(url, { data = {}, contentType = 'application/json' } = {}) {
    try {
        const token = localStorage.getItem('authen_access_token')
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': contentType,
            },
            body: JSON.stringify(data),
        });
        if (res.status !== 200) {
            console.log(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
            return;
        }
        const responeData = await res.json();
        if (responeData.status === 1) {
            return responeData;
        } else {
            if(responeData.message == "Expired token") {
                alert(`Phiên đăng nhập đã hết hạn`);
                window.location.assign(domains.REACT_APP_HOST_DOMAIN_TSA);
                return
            }
            alert(`${responeData.message}`);
        }
    } catch (error) {
        console.log(`${error.message}`);
    }
}

async function getRequest(url, token) {
    try {
        let access_token;
        if(token) {
            access_token = localStorage.getItem('authen_access_token')
        }
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${access_token}`,
            },
        });
        if (res.status !== 200) {
            // alertUtils.error(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
            console.log(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
            return;
        }
        const responeData = await res.json();
        if (responeData.status === 1) {
            return responeData;
        } else {
            if(responeData.message === "Expired token") {
                alert(`Phiên đăng nhập đã hết hạn`);
                window.location.assign(domains.REACT_APP_HOST_DOMAIN_TSA);
                return
            }
            alert(`${responeData.message}`);
        }
    } catch (error) {
        // alertUtils.error(`${error.message}`);
        console.error(error);
    }
}

// async function CheckCodeUser(code) {
//     console.log('domains.URL_FILE_USER', domains.URL_FILE_USER)
//     const timeStamp = moment().unix();
//     const data = await fetch(`${domains.URL_FILE_USER}?t=${timeStamp}`) // Thay URL bằng link JSON của bạn
//         .then((response) => response.json())
//         .then((jsonData) => {
//             const user = jsonData.data.find( e => e.code === code )
//             if(user) {
//                 window.location.assign(`${domains.PUBLIC_URL}?name=${user.name}&room=${user.room}&is_admin=${user.is_admin}&color=${user.color}&app=true`)
                
//                 return
//             }
//             alert(`code đăng nhập không đúng`);
//         })
//         .catch((error) => console.error("Lỗi khi fetch JSON:", error));
      
// }
async function CheckCodeUser(url, code) {
    const contentType = 'application/json';
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': contentType,
        },
        body: JSON.stringify({
            code: code
        }),
    });
    if (res.status !== 200) {
        // alertUtils.error(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
        console.log(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
        return;
    }
    const responeData = await res.json();
    if (responeData.status === 1) {
        const user = responeData.data
        return window.location.assign(`${domains.PUBLIC_URL}?name=${user.name}&room=${user.room}&is_admin=${user.is_admin}&color=${user.color}&app=true`)
    } else {
        if(responeData.message === "Expired token") {
            alert(`Phiên đăng nhập đã hết hạn`);
            window.location.assign(domains.REACT_APP_HOST_DOMAIN_TSA);
            return
        }
        alert(`${responeData.message}`);
    }
      
}
async function UploadFile(url, { file = {}} = {}) {
    const token = localStorage.getItem('authen_access_token')
    const formData = new FormData();
    formData.append("file", file);
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
    });
    if (res.status !== 200) {
        console.log(`Cannot connect to server. Your connect has error: '${res.statusText}'`);
        return;
    }
    const responeData = await res.json();
    if (responeData.status === 1) {
        return responeData;
    } else {
        console.log(responeData);
        alert(`${responeData.message}. Mong bạn hãy kiểm tra lại.`);
    }
}
export const httpUtils = {
    postRequest,
    getRequest,
    UploadFile,
    CheckCodeUser
};
